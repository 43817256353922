import * as React from 'react'
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout'

import * as moment from 'moment';

import './radioshows.scss';
import { IRadioShowEdges } from '../typings';
import { RadioShow } from '../components/data/radioShow';

interface IProps {
  data?: { radioShows: IRadioShowEdges };
}

export const RadioShowsPage: React.FunctionComponent<IProps> = props => {
  const { radioShows } = props.data;
  return (
    <Layout className='page radio-shows-page' pageName='radio shows'>
      <h3>Radio Shows</h3>
      <div className="radio-shows-list">
        {!!radioShows && radioShows.edges.length && radioShows.edges.map((radioShow, i) =>
          <React.Fragment key={radioShow.node.title + i}>
            {(i == 0 || moment(radioShow.node.date).year() !== moment(radioShows.edges[i - 1].node.date).year()) ?
              <h3>{moment(radioShow.node.date).format('YYYY')}</h3>
              : ''
            }
            <RadioShow radioShow={radioShow.node} />
          </React.Fragment>
        )}
      </div>
    </Layout>
  )
}

export default RadioShowsPage;

export const query = graphql`
query RadioShowsQuery{
  radioShows: allMarkdownRemark(
      filter: { collectionKey: { eq: "radioShow" } },
      sort: { order: DESC, fields: [date] },
    ){
    edges
    {
      node
      {
        title
        collectionKey
        mixcloudurl
        station
        image
        imageSmall
        date
        fields
        {
          path
        }
      }
    }
  }
}`
